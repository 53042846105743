import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import Top from "../components/Top/Top"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Home" />
    <Top />
    <div className="main mainRaised">
      <h1>NOT FOUND</h1>
    </div>
  </Layout>
)

export default NotFoundPage
